.seasonSelectorContainer {
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;

  @media (width >= 768px) {
    align-items: center;
    flex-direction: row;
  }
}

.seasonSelectorContent {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;

  @media (width >= 768px) {
    width: fit-content;
  }
}

.tournamentsListContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tournamentItem {
  background-color: #070f15d9;
  border-radius: 12px;
  padding: 20px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
}

.tournamentItemInfo {
  display: flex;
  gap: 12px;
  align-items: center;
}

.tournamentNameContainer {
  display: flex;
  flex-direction: column;
}

.tournamentLogo {
  width: 80px;
  height: 80px;
}
