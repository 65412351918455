.title {
  font-size: 48px;
  font-weight: 900;
  color: #d0d5dd;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #070f15bf;
  border: 2px solid #272c32;
  border-radius: 12px;
  padding: 20px;

  @media (width >= 768px) {
    gap: 40px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (width >= 768px) {
    gap: 40px;
  }
}

.labelValueContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (width >= 768px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.labelValueContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (width >= 768px) {
    border: 2px solid #272c32;
    border-radius: 12px;
    padding: 17px 32px;
    width: 48%;
  }
}

.label {
  font-size: 14;
  font-weight: 500;

  @media (width >= 768px) {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.value {
  text-align: end;
  font-size: 15px;
  font-weight: 500;

  @media (width >= 768px) {
    font-size: 20px;
    font-weight: 700;
  }
}

.playerContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  @media (width >= 768px) {
    gap: 12px;
  }
}

.playerContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.sanctionTypeName {
  padding: 16px 20px;
  border: 2px solid #272c32;
  background-color: #070f15bf;
  border-radius: 12px;

  & > p {
    font-size: 18px;
    font-weight: 600;
    color: #d0d5dd;
    text-transform: uppercase;
    text-align: center;
  }
}

.tournamentContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  @media (width >= 768px) {
    justify-content: space-between;
  }
}

.tournamentContainer2 {
  display: flex;
  gap: 8px;
}

.tournamentName {
  font-size: 24px;
  font-weight: 600;
}

.playerInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  @media (width >= 768px) {
    justify-content: unset;
  }
}

.playerDorsalMobile {
  display: flex;
  align-items: center;
  gap: 2px;
}

.playerName {
  font-size: 18px;
  font-weight: 600;
  color: white;

  @media (width >= 768px) {
    font-size: 36px;
    font-weight: 700px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sectionTitle {
  font-size: 17px;
  font-weight: 900;
  text-transform: uppercase;

  @media (width >= 768px) {
    font-size: 20px;
  }
}

.sanctionContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (width >= 768px) {
    gap: 8px;
  }
}

.sanctionContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sanctionDate {
  font-size: 14px;
  font-weight: 700;

  @media (width >= 768px) {
    font-size: 16px;
    font-weight: 600;
  }
}

.sanctionText {
  font-size: 14px;
  font-weight: 400;

  @media (width >= 768px) {
    font-size: 16px;
    font-weight: 400;
  }
}

.allegationsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.allegationsContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border: 2px solid #272c32;
  border-radius: 12px;

  @media (width >= 768px) {
    gap: 24px;
    padding: 24px 32px;
  }
}

.linkContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.teamLink {
  font-size: 16px;
  font-weight: 600;

  @media (width >= 768px) {
    font-size: 20px;
    font-weight: 600;
  }
}

.teamLink2 {
  font-size: 36px;
}

.allegations {
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > * {
    color: #d0d5dd;
  }
}
