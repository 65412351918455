.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (width >= 768px) {
    gap: 40px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @media (width >= 768px) {
    gap: 24px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (width >= 768px) {
    gap: 24px;
  }
}

.title {
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  vertical-align: middle;
  text-transform: uppercase;
  color: #ffffff;

  @media (width >= 768px) {
    font-size: 24px;
    line-height: 36px;
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 4px;

  background-color: #070f15bf;
  border-radius: 8px;
  border: 2px solid #0d121c;
  padding: 8px;

  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #d0d5dd !important;

  @media (width >= 768px) {
    padding: 10px 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}
