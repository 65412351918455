.root {
  min-width: 320px;
  max-width: 1560px;
  width: 100%;
  margin: auto;
  padding: 0 120px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (width <= 768px) {
    width: 100%;
    padding: 0 8px;
  }

  @media (width > 768px) {
    gap: 20px;
  }
}
