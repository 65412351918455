.teamImg {
  max-height: 337px;
  border-radius: 20px;
  @media (width >= 768px) {
    width: 100%;
    max-height: 722px;
    margin-bottom: 30px;
    border: 2px solid #e7a016;
    overflow: hidden;
    background-color: #3e3c52;
  }
  
  img {
    width: 100%;
    display: block;
  }
}

.apparelBackground {
  border: none !important;
  background-color: #272C32;
}

.avatarImg {
  border: none !important;
}
