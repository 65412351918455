.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  @media (width >= 768px) {
    width: 100%;
    flex-direction: row;
  }
}

.stageSelectorContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  @media (width >= 768px) {
    width: fit-content;
  }
}

.stageLabel {
  display: none;
  @media (width >= 768px) {
    display: block;
  }
}

.dropdown {
  width: 100%;

  @media (width >= 768px) {
    width: fit-content;
  }
}
