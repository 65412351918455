.button {
  all: unset;
  cursor: pointer;

  display: inline-block;
  color: white;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #1f232c;

  padding: 12px 13px;
}

.language {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.languageSelector {
  z-index: 3;
  position: absolute;
  border-radius: 8px;
  background-color: #1f232c;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 4px;
}

.languageOptionButton {
  all: unset;
  cursor: pointer;

  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 28px;
  padding: 4px 8px;
  border-radius: 4px;

  &:hover {
    background-color: #383e47;
  }
}

.languageOptionText {
  font-size: 16px;
  font-weight: 500;
  color: #d0d5dd;
}

.overlay {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.5); */
}
