.matchHeader {
  @media (width < 768px) {
    & > div {
      padding: 0 !important;
    }
  }
}

.container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (width >= 768px) {
    height: 278px;
    overflow: visible;
  }
}

.content {
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 16px 0;
  z-index: 2;
  position: inherit;

  @media (width >= 768px) {
    width: 100%;
    gap: 20px;
  }
}

.tournamentBackgroundLogo {
  width: 310px;
  height: 310px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  top: -50px;
  position: absolute;
  filter: grayscale(100%) brightness(0.5);
  z-index: 1;
  border-radius: 50%;

  @media (width >= 768px) {
    width: 278px;
    height: 278px;
    top: auto;

    /* transform: none; */
  }
}

.teamContainerLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;

  @media (width >= 768px) {
    width: 100%;
    flex-direction: row-reverse;
    gap: 20px;
    padding: 20px 0;
  }
}

.teamContainerRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;

  @media (width >= 768px) {
    width: 100%;
    flex-direction: row;
    gap: 20px;
    padding: 20px 0;
  }
}

.teamLogo {
  width: 72px;
  height: 72px;

  @media (width >= 768px) {
    width: 120px;
    height: 120px;
  }

  & > img {
    width: 72px;
    height: 72px;
    border-radius: 50%;

    @media (width >= 768px) {
      width: 120px;
      height: 120px;
    }
  }
}

.teamInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.teamName {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  color: #ffffff;
  text-transform: uppercase;

  @media (width > 768px) {
    font-size: 40px;
    line-height: 44px;
  }

  @media (width >= 768px) and (width < 1280px) {
    font-weight: 900;
    font-size: 20px;
    line-height: 35px;
  }
}

.flagContainer {
  display: none;

  @media (width >= 768px) {
    display: flex;
    align-items: center;
    gap: 8px;

    & > p {
      font-weight: 900;
      font-size: 17px;
      line-height: 21px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
}

.flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 40px;
}

.matchStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  @media (width >= 768px) {
    width: fit-content;
    gap: 12px;
  }
}

.tournamentLogo {
  width: 40px;
  height: 40px;
  background-size: contain;
  border-radius: 50%;

  @media (width >= 768px) {
    width: 64px;
    height: 64px;
  }
}

.scores {
  width: max-content;
  padding: 8px 12px;
  background-color: #070f15d9;
  border: 1px solid #272c32;
  border-radius: 8px;
}

.scoreText {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;

  @media (width >= 768px) {
    font-size: 48px;
    line-height: 60px;
  }
}

.statusText {
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;

  @media (width >= 768px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
}
