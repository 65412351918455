.matchesList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: -webkit-fill-available;
  padding: 12px 8px;
  background-color: #070f15bf;

  & > div > * {
    border-radius: 12px;
  }

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  @media (width > 768px) and (width < 1140px) {
    flex-direction: column;
  }

  @media (width >= 768px) {
    padding: 10px 20px;
  }
}

.leagueInfo {
  display: flex;
  align-items: center;
  width: 100%;

  @media (width >= 768px) {
    pag: 8px;
  }

  @media (width > 768px) and (width < 1140px) {
    justify-content: space-between;

    & > div {
      display: flex;
      gap: 8px;
    }
  }
}

.leagueInfoMobile {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;

  @media (width >= 768px) {
    display: none;
  }
}

.leagueLogo {
  width: 40px;
  height: 40px;
}

.youtubeOpacity {
  opacity: 0.5;
  cursor: not-allowed;
}

.leftSection {
  @media (width >= 768px) {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.centerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;

  @media (width >= 768px) {
    width: 55%;
  }

  @media (width >= 768px) and (width < 1140px) {
    & > div {
      padding: 0;
    }
  }
}

.rightSection {
  width: 20%;

  @media (width >= 768px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 64px;
}

.borderTopRadius {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.dateMobileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;

  @media (width >= 768px) {
    line-height: 18px;
  }
}

.gapped {
  border-radius: 12px;

  @media (width >= 768px) {
    border-radius: 0;
  }
}
