.containerMatch {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  gap: 6px;
  text-align: right;
  min-height: 64px;

  @media (width >= 768px) {
    min-height: 72px;
  }
}

.background {
  background-color: #070f15d9;
}

.teamContainerLeft {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 6px;
}

.teamContainerRight {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
}

.teamLogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.teamName {
  width: 102.5px;
  color: #fff;
  /* width: 100%; */
}

.teamNameLeft {
  text-align: left;

  @media (width < 768px) {
    font-size: 12px !important;
  }
}

.teamNameRight {
  text-align: right;

  @media (width < 768px) {
    font-size: 12px !important;
  }
}

.matchTime {
  padding: 8px 0px;
  border-radius: 8px;
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
  min-width: 60px;
  text-align: center;

  @media (width < 768px) {
    margin: 0;
  }
}

.matchLiveIndicator {
  position: absolute;
  left: 11px;
  bottom: -13px;
}
