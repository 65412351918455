.container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #070f15bf;
  border: 2px solid #0d121c;
  border-radius: 16px;
}

.hideStylesOnDesktopView {
  @media (width >= 768px) {
    padding: unset;
    background-color: unset;
    border: unset;
    border-radius: unset;
  }
}
