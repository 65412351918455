.button {
  min-height: 44px;
  border-radius: 8px;
  background-color: white !important;
  color: black !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.buttonSecondary {
  min-height: 44px;
  border-radius: 12px;
  background-color: #070f15d9 !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border: 2px solid #272c32 !important;
  padding: 18px 20px !important;
  display: flex;
  gap: 8px;
}
