.container {
  display: none;

  @media (width >= 768px) {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 8px;
    align-items: center;
    justify-content: space-around;
  }
}

.swiperSlide {
  /* Center slide text vertically */
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 160px;
  width: 240px;
  text-align: center;
  color: white;
}

.card {
  justify-content: center;
  align-items: center;
  background-color: #070f15d9;
  border: 2px solid #272c32;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 8px 12px;
}

.cardBody {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 20px 41px;
  border-top: 2px solid #272c32;

  @media (width >= 768px) {
    padding: 30px 20px 40px;
  }
}

.cardValue {
  font-weight: 900;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  vertical-align: middle;
  color: #6df775;

  @media (width >= 768px) {
    font-size: 52px;
    line-height: 100%;
    letter-spacing: -6%;
  }
}

.cardIconContainer {
  width: 32px;
  height: 32px;

  @media (width >= 768px) {
    width: 40px;
    height: 40px;
  }
}

.cardLabel {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  vertical-align: middle;
  text-transform: uppercase;
  color: #a3a9b5;
  text-align: end;

  @media (width >= 768px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
}
