.teamDataContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.teamLogo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.teamName {
  font-size: 14px;
  width: max-content;

  @media (width > 768px) {
    font-size: 16px;
  }
}

.stageSelectorContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}

.dayTitle {
  font-size: 32px;
  font-weight: 800;
  color: #d0d5dd;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 64px;
}

.statusContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
