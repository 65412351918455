.header {
  background-color: #070f15d9;

  position: sticky;
  z-index: 4;
  top: 0;
  left: 0;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  gap: 12px;

  @media (768px <= width < 1023px) {
    padding: 24px 60px 12px;
  }

  @media (width >= 1023px) {
    padding: 24px 120px 12px;
  }
}

.headerLinks {
  display: none;

  @media (width >= 768px) {
    display: flex;
    align-items: center;
    gap: 27px;
  }
}

.topBarContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  @media (width >= 768px) {
    justify-content: unset;
  }
}

.topBarContainerLeft {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.orgMotto {
  text-transform: none;
}

.topBarContainerRight {
  display: none;

  @media (width >= 768px) {
    display: flex;
    gap: 24px;
  }
}

.logo {
  height: 56px;
  width: auto;
}

.topBarMenuIcon {
  all: unset;
  display: inline-block;
  cursor: pointer;

  @media (width >= 768px) {
    display: none;
  }
}

.linksContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 32px;
  height: 100%;
}

.followUsText {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.link {
  cursor: pointer;

  & > a {
    /* color: #D0D5DD !important; */

    @media (width >= 768px) {
      padding: 12px 20px;
    }
  }

  @media (width >= 768px) {
    padding: 12px 0px;
  }
}

.linkSelected {
  /* color: white !important; */
  @media (width >= 768px) {
    border-radius: 8px;
    border: 2px solid #383e47;
    background-color: #1f232c;
  }
}

.signinLink {
  font-size: 16px;
  font-weight: 700;
  color: white !important;
  width: max-content;
}

.socialLinks {
  display: flex;
  gap: 8px;
}

.socialLink {
  width: 48px;
  height: 48px;
  text-indent: unset !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.signinContainer {
  display: flex;
  width: 100%;
  align-items: flex-end;

  & > li {
    width: 100%;
  }
}
