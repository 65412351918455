.small {
  width: 32px;
  height: 32 px;
  object-fit: cover;
  border-radius: 50%;
}

.medium {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.large {
  width: 112px;
  height: 112px;
  border-radius: 50%;
}
