.container {
  display: flex;
  width: 14px;
  height: 14px;
}

.content {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.success {
  background-color: #12b76a;
}

.warning {
  background-color: #ff833d;
}

.error {
  background-color: #f04438;
}
