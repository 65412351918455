.rankContainer {
  position: relative;
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rankFlagContainer {
  padding: 8px 0;
  width: 6px;
  position: absolute;
  left: -20px;
  width: 6px;
  height: 100%;
}

.rankFlag {
  width: 6px;
  height: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rank {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background-color: #272c32;
  font-size: 16px;
  font-weight: 700;
}
