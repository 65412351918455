.awardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: 280px;
  padding: 16px 13px;
  background-color: #070f15bf;
  border: 2px solid #0d121c;
  border-radius: 15px;
  gap: 12px;

  @media (width >= 768px) {
    width: 248px;
    height: 352px;
    padding: 24px;
    gap: 16px;
    border-radius: 20px;
  }
}

.awardImageWrapper {
  width: 100%;
  height: 100%;
  max-width: 152px;
  max-height: 160px;
  padding: 12px;

  @media (width >= 768px) {
    max-width: 200px;
    max-height: 200px;
  }
}

.awardImage {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.awardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.awardTitle {
  & > a {
    display: block;
    width: 100%;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #ffffff !important;

    @media (width >= 768px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.awardDescription {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #d0d5dd !important;

  @media (width >= 768px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.awardDescriptionLink {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff !important;

  @media (width >= 768px) {
    font-size: 16px;
    line-height: 20px;
  }
}
