.gray100 {
  color: #f2f4f7;
}

.gray300 {
  color: #d0d5dd;
}

.gray400 {
  color: #959aa2;
}
