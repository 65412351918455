.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  min-width: 320px;

  @media (width >= 768px) {
    display: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 18px 20px;
  background-color: #070f15d9;
  box-sizing: border-box;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: white;
  margin: 0;
}

.closeButton {
  all: unset;
  display: inline-block;
  cursor: pointer;
}

.modalContent {
  width: 100%;
  height: 100%;
  padding: 32px;
  background-color: #1f232c;
  box-shadow: inset 0 0 0 2px #383e47;
  border-radius: 20px 20px 0 0;
  overflow: auto;
}
