.title {
  font-weight: 900;
  font-size: 24px;
  color: #959aa2;
  margin: 0 !important;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectorsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (width >= 768px) {
    gap: 40px;
  }
}