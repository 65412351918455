.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #070f15bf;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  min-width: 320px;
  padding: 0px;
  box-sizing: border-box;

  @media (width >= 768px) {
    padding: 80px;
  }
}

.modalContent {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #070f15bf;
  box-shadow: inset 0 0 0 2px #383e47;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 24px 40px;
  box-sizing: border-box;
}

.headerContent {
  width: 100%;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: white;
  margin: 0;
}

.closeButton {
  all: unset;
  display: inline-block;
  cursor: pointer;
  display: flex;
  background-color: #070f15bf;
  border: 2px solid #272c32;
  border-radius: 12px;
  padding: 14px;
}

.body {
  padding: 40px;
  border-radius: 20px;
  overflow-y: auto;
  flex-grow: 1;
  box-sizing: border-box;
}
