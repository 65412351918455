.container {
  overflow: auto;
  cursor: grab;
}

.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 2px;
}
