.container {
  gap: 20px;

  @media (width >= 768px) {
    gap: 40px;
  }
}

.title {
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  text-transform: uppercase;
  color: #ffffff;

  @media (width >= 768px) {
    font-weight: 900;
    font-size: 48px;
    line-height: 44px;
    color: #a3a9b5;
  }
}

.dorsalContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 46px;

  @media (width >= 768px) {
    gap: 8px;
  }
}

.eventBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: #070f15d9;
  border: 2px solid #272c32;
  border-radius: 8px;
  padding: 8px 12px;

  @media (width >= 768px) {
    padding: 12px;
  }
}

.headerEventBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;

  @media (width >= 768px) {
    height: 104px;
  }
}

.time {
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #d0d5dd;

  @media (width >= 768px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

.type {
  width: 100%;
}

.eventType {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.typeText {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  text-transform: uppercase;

  @media (width >= 768px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
  }
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 12px;
}

.entryBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.entryBoxRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.entryBoxLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.central {
  text-align: center;
}

.playerName {
  display: none;

  @media (width >= 768px) {
    display: block;

    & > a {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff !important;
      text-transform: lowercase;
      display: inline-block;
    }

    & > a::first-letter {
      text-transform: uppercase;
    }
    & > a::first-line {
      text-transform: capitalize;
    }
  }
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.teams {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.teamLogo {
  width: 56px;
  height: 56px;
  border-radius: 50%;

  @media (width >= 768px) {
    width: 104px;
    height: 104px;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
}

.teamInfo {
  display: none;

  @media (width >= 768px) {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.teamName {
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.teamFrom {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #d0d5dd;
}

.card {
  width: 12px !important;
  height: 17px !important;
  margin: 0 !important;
  border-radius: 2px !important;
  border: 2px solid #1f232c;
}
