.marquee {
  overflow-x: clip;

  & > div > div {
    gap: 8px;
    min-width: unset;
  }

  & > div {
    min-width: unset;
    margin-right: 8px;
  }

  & :nth-child(2) {
    gap: 8px;
  }
}
