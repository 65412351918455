.containerDesktop {
  display: none;
  @media (width > 768px) {
    display: block;
    padding: 20px 0;
  }
}

.containerMobile {
  display: block;
  /* background-color: antiquewhite; */
  border-radius: 12px;
  padding: 12px 16px;

  @media (width > 768px) {
    display: none;
  }
}

.marqueeItem {
  width: 100%;
  border-radius: 20px;

  & > div {
    border-radius: 20px;
  }
}

.matchRow {
  padding: 20px 10px;

  & > div {
    margin: 0;
  }
}
