.title {
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;
  text-transform: uppercase;

  @media (width >= 768px) {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (width >= 768px) {
    gap: 26px;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @media (width >= 768px) {
    gap: 16px;
  }
}

.referee {
  display: flex;
  align-items: center;
  gap: 8px;

  @media (width >= 768px) {
    gap: 20px;
  }
}

.imageWrapper {
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 12px 0px #6ef8bd;

  @media (width >= 768px) {
    width: 88px;
    height: 88px;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 50%;
    background: linear-gradient(90deg, #6df775 -3.29%, #77ffb2 104.19%);
    -webkit-mask:
      linear-gradient(white 0 0) content-box,
      linear-gradient(white 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  & > img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;

    @media (width >= 768px) {
      width: 88px;
      height: 88px;
    }
  }
}

.name {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: #ffffff;

  @media (width >= 768px) {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
  }
}
