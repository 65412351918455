.stageSelectorContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}

.dayTitle {
  font-size: 32px;
  font-weight: 800;
  color: #d0d5dd;
}
