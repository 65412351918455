.section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (width >= 768px) {
    flex-direction: row;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (width >= 768px) {
    gap: 40px;
  }
}

.playerGamesContainer {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.playerGamesDetailsContainer {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.playerGamesDetailsContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.playerGamesDetailsContentHeader {
  display: flex;
  align-items: center;
  gap: 4px;

  & > p {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
}

.playerGamesDetailsContentBody {
  display: flex;
  align-items: baseline;

  & > span {
    font-weight: 900;
    font-size: 17px;
    line-height: 21px;
  }

  & > p {
    font-weight: 900;
    font-size: 32px;
    line-height: 36px;
  }
}

.playerGamesDetailsContentText {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #a3a9b5;
}

.playerPerformanceContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.playerPerformanceContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.playerPerformanceBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.sectionTitle {
  display: none;

  @media (width >= 768px) {
    display: block;
    font-weight: 900;
    font-size: 48px;
    line-height: 44px;
    text-transform: uppercase;
    color: #a3a9b5;
  }
}

.pieChartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.fairplayContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.fairPlayContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.pieLabel {
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  text-align: right;
  text-transform: uppercase;
  color: #d0d5dd;
}

.pieValue {
  font-weight: 900;
  font-size: 32px;
  line-height: 36px;
  color: #d0d5dd;
}

.fairPlayValue {
  font-family: Inter;
  font-weight: 900;
  font-size: 21px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;

  @media (width >= 768px) {
    font-size: 24px;
    line-height: 36px;
  }
}

.fairPlayValueUnit {
  font-weight: 900;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
}

.fairPlayContentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.cardRowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.cardsContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cardRowLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}

.card {
  width: 14px;
  height: 18px;
  border-radius: 2px;
  outline: 2px solid #1f232c;
}

.cardRowRight {
  display: flex;
  align-items: flex-end;
  gap: 4px;
}

.title {
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.text {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #d0d5dd;
}

.text2 {
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #a3a9b5;
  text-transform: uppercase;
}

.text3 {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #a3a9b5;
}

.performanceTextValue1 {
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;

  @media (width >= 768px) {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
  }
}

.units {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #d0d5dd;
  text-transform: uppercase;
}

.x {
  font-weight: 900;
  font-size: 15px;
  line-height: 19px;
  color: #ffffff;
}

.value {
  font-weight: 900;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
}
