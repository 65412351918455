.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #070f15d9;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  @media (width < 768px) {
    flex-direction: column;
    padding: 12px;
    gap: 12px;
  }
}

.headerSection {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  & > p {
    color: white;
  }
}

.matchesList {
  background-color: #070f15d9;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  gap: 8px;

  & > div > * {
    border-radius: 20px;
  }

  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  @media (width > 768px) and (width < 1140px) {
    flex-direction: column-reverse;
  }

  @media (width >= 768px) {
    padding: 0px 20px;
  }
}

.leagueInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.leagueLogo {
  width: 40px;
  height: 40px;
}

.youtubeOpacity {
  opacity: 0.5;
}

.separator {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;

  @media (width >= 768px) {
    display: none;
  }
}

.leftSection {
  display: none;

  @media (width >= 768px) {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 35%;
  }

  @media (width > 768px) and (width < 1140px) {
    width: 100%;
    justify-content: space-between;
  }
}

.centerSection {
  width: 100%;
  justify-content: center;

  @media (width >= 768px) {
    width: 45%;
  }

  @media (width > 768px) and (width < 1140px) {
    width: 100%;

    & > div {
      padding: 0;
    }
  }
}

.rightSection {
  display: none;

  @media (width >= 768px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: 20%;
  }

  @media (width > 768px) and (width < 1140px) {
    width: 100%;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 64px;
}

.dropdown {
  width: 100%;

  @media (width >= 768px) {
    width: fit-content;
  }
}

.footerSection {
  background-color: #070f15d9;
  border-top: 2px solid #272c32;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  padding: 16px 0;
  align-items: center;
}
