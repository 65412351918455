.section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;

  @media (width >= 768px) {
    margin-top: 100px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 900 !important;
  font-size: 25px !important;
  line-height: 29px !important;
  text-transform: uppercase;
  color: #d0d5dd !important;
  margin: 0 !important;

  @media (width >= 768px) {
    font-size: 48px !important;
    line-height: 44px !important;
  }
}

.moreNewsContainer {
  gap: 13px;
  text-align: right;
  padding: 12px;
}

.moreNewsTitle {
  color: #ffffff !important;
  margin: 0 !important;
}

.newsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  @media (width >= 768px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
    gap: 8px;
    justify-content: center;
  }
}

.swiperSlide {
  text-align: center;
  font-size: 18px;
  background: #070f15d9;

  /* Center slide text vertically */
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 240px !important;
  height: 300px !important;
  border-radius: 12px;
  border: 2px solid #272c32;

  @media (width >= 768px) {
    width: 100% !important;
    height: auto !important;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  padding: 20px;
  height: 100%;
  max-height: 161px;

  @media (width>= 768px) {
    padding: 24px;
    max-height: 180px;
  }
}

.timestamp {
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  margin-bottom: 8px;
  text-align: left;

  @media (width >= 768px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}

.videoTitle {
  font-size: 17px;
  line-height: 21px;
  font-weight: 500;
  text-align: left;

  @media (width >= 768px) {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }
}

.subtitle {
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  text-align: left;

  @media (width >= 768px) {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
}
