.button {
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;
  border: 2px solid #272c32;
}

.container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.text {
  font-size: 16px;
  font-weight: 600;
}

.icon {
  width: 24px;
  height: 24px;
}
