.item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 120px;
  width: 100%;
  border-radius: 20px;

  @media (width >= 768px) {
    height: 220px;
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.image {
  object-fit: contain;
  border-radius: 20px;
  height: -webkit-fill-available;
  width: 100%;

  @media (width >= 768px) {
    height: unset;
  }
}

.sponsorContainer {
  display: grid;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  width: 50%;
  align-self: center;

  @media (width >= 768px) {
    display: flex;
    width: 100%;
  }
}
