.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.checkBox {
  min-width: 24px;
  min-height: 24px;
  border-radius: 4px;
  cursor: pointer;

  @media (width >= 768px) {
    min-width: 36px;
    min-height: 36px;
  }
}
