.container {
  display: flex;
  gap: 12px;
  width: 100%;

  @media (width >= 768px) {
    width: auto;
  }
}

.playerStats {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  width: 100%;

  @media (width >= 768px) {
    width: auto;
    justify-content: unset;
    gap: 8px;
  }
}

.multipleEventsContainer {
  display: flex;
  align-items: center;
  padding: 1px 6px 1px 2.5px;
  gap: 3px;
  background-color: #f9fafb;
  border-radius: 30px;
  border: 2px solid #1f232c;

  & > p {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #1f232c;
  }
}

.cardsContainer {
  display: flex;
}

.card {
  width: 12px !important;
  height: 17.25px !important;
  margin: 0 !important;
  border: 2px solid #1f232c;
  border-radius: 3px !important;
}
