.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;

  @media (width >= 768px) {
    font-size: 48px;
    line-height: 44px;
    color: #a3a9b5;
  }
}

.swiperSlide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 280px;
  width: 180px;
  text-align: center;
  color: white;
  margin-right: 4px !important;

  @media (width >= 768px) {
    height: 352px;
    width: 248px;
    margin-right: 8px !important;
  }
}
