.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  color: white;
  text-transform: capitalize;
}

.input {
  width: 100%;
  padding: 20px;
  border: 2px solid #383e47;
  border-radius: 8px;
  min-height: 60px;
  background-color: #070f15bf;

  :global(&:-webkit-autofill) {
    background-color: inherit !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
}
