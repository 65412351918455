.container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.logo {
  width: 104px;
  height: 104px;
}
