.container {
  /* height: 100%;
  width: 100%; */
  position: relative;
}

.header {
  position: absolute;
  top: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
}

.headerLeft {
  display: flex;
  gap: 2px;
  transform: rotateZ(-15deg);
}

.headerRight {
  transform: rotateZ(15deg);
}

.text {
  font-size: 7px;
  font-weight: 900;
  color: white;
}

.image {
  position: absolute;
  top: 11px;
  left: 1px;
  width: 61px;
  height: 66px;
  clip-path: polygon(50% 0%, 95% 11%, 100% 100%, 50% 100%, 0% 100%, 5% 11%);
}

.footer {
  position: absolute;
  bottom: 22px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 0 1px;
  clip-path: polygon(50% 0%, 97% 0%, 100% 100%, 50% 100%, 0% 100%, 3% 0%);

  & > p {
    text-align: center;
    background-color: #2a2a2a;
  }
}
