.section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.teamLogo {
  width: 144px;
  height: 144px;
  @media (width >= 768px) {
    width: 160px;
    height: 160px;
  }
}

.content {
  width: 100%;

  @media (width >= 900px) {
    flex: 1;
  }
}

.labelValuesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 16px;
  width: 100%;
  gap: 4px;
}

.rankingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (width >= 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: unset;
  }
}

.rankingContent {
  display: flex;
  align-items: center;
  gap: 3px;
}

.positionRanking {
  font-size: 28px;
  font-weight: 900;
  color: white;

  @media (width >= 768px) {
    font-size: 56px;
    font-weight: 900;
    color: inherit;
  }
}

.positionRankingValue {
  font-size: 21px;
  font-weight: 900;
  color: white;

  @media (width >= 768px) {
    font-size: 56px;
    font-weight: 900;
    color: inherit;
  }
}

.pointsRanking {
  font-size: 21px;
  font-weight: 900;
  color: white;

  @media (width >= 768px) {
    font-size: 30px;
    font-weight: 700;
  }
}

.pointsRankingUnit {
  font-size: 21px;
  font-weight: 900;
  color: white;

  @media (width >= 768px) {
    font-size: 30px;
    font-weight: 700;
  }
}
