.playerData {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.playerDataHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media (width >= 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.playerDataMainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media (width >= 768px) {
    flex-direction: row;
    gap: 20px;
  }
}

.playerDataAvatar {
  min-width: 128px;
  min-height: 128px;
  width: 128px;
  height: 128px;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #ff1942;
    box-shadow: 0px 2px 4px 0px #ff1942;
    box-shadow: 0px 8px 16px 0px #ff1942;
  }

  @media (width >= 768px) {
    min-width: 200px;
    min-height: 200px;
    width: 200px;
    height: 200px;
  }
}

.playerDataName {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.teamName {
  & > a {
    font-size: 15px;
    font-weight: 900;
    color: #ffffff !important;
    line-height: 19px;
  }
}

.playerName {
  font-size: 32px;
  font-weight: 900;
  color: #ff1942;
  text-align: center;
  line-height: 32px;
}

.playerDataPosition {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (width >= 768px) {
    flex-direction: column-reverse;
  }
}

.positionName {
  font-size: 15px;
  font-weight: 900;
  color: #ffffff !important;
  line-height: 19px;
}

.positionNumber {
  font-size: 32px;
  font-weight: 900;
  color: #ff1942;
  line-height: 32px;
}

.playerDataFooter {
  display: flex;
  gap: 4px;
  width: 100%;
}

.playerDataFooterBox {
  display: flex;
  gap: 8px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px;
  border: 2px solid #0d121c;
  border-radius: 12px;
  background-color: #070f15bf;

  @media (width >= 768px) {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
}

.playerDataFooterTitle {
  font-size: 12px;
  font-weight: 600;
  color: #a3a9b5;
  line-height: 14px;
  text-transform: uppercase;

  @media (width >= 768px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}

.playerDataFooterValue {
  font-size: 21px;
  font-weight: 900;
  color: #ffffff;
  line-height: 25px;

  @media (width >= 768px) {
    font-size: 32px;
    line-height: 36px;
  }
}
