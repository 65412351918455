.teamDataContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.teamName {
  width: max-content;
  font-size: 16px;
}

.teamLogo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.lastGamesContainer {
  display: flex;
  gap: 6px;
}

.last5GamesIcon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.updatePositionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
