.container {
  display: flex;
  overflow: visible;
  width: 100%;
  overflow: auto;
}

.link {
  cursor: pointer;

  & > button {
    padding: 12px 16px;

    @media (width >= 768px) {
      padding: 16px 20px;
    }
  }

  & > a {
    padding: 12px 16px;

    @media (width >= 768px) {
      padding: 16px 20px;
    }
  }
}

.linkSelected {
  border-radius: 8px;
  border: 2px solid #383e47;
  background-color: #1f232c;
}

.linkText {
  font-weight: 600;
}
