.columnStage {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 8px;

  @media (width >= 768px) {
    gap: 12px;
  }
}
