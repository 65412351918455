.container {
  border: 2px solid #272c32;
  border-radius: 20px;
  overflow: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #070f15b2;
}

.tableHeaderRow {
  text-transform: uppercase;
  border-bottom: 2px solid #272c32;
}

.headerCell {
  padding: 17px 5px;
}

.dataTableRow {
  text-align: center;
}

.borderBottom {
  border-bottom: 1px solid #272c32;
}

.column {
  padding: 0 20px;
  height: 72px;

  & > a {
    display: flex;
    height: 72px;
    align-items: center;
    white-space: nowrap;
  }
}
