.dream-team_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.fieldContainer {
  position: relative;
}

.field {
  width: 100%;
  height: 100%;
}

.team1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 0 0 20px 0;
}

.team2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0 0 0;
}

.row {
  display: grid;
  width: 100%;
}

.player {
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.empty-player {
  background-color: transparent;
  width: 40px;
  height: 40px;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  z-index: 2;
}

label {
  font-size: 16px;
}

select {
  margin-left: 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #070f15d9;
}

.playerHorizontal {
  transform: rotate(-90deg) rotateY(0deg) rotateX(0deg);
  transform-origin: center;
}

.tactic {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-position: center;
  padding: 80px 12px 30px;
  border-radius: 10px;
}

.tacticWithTwoTeams {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-position: center;
  border-radius: 10px;
  padding: 12px 12px 30px;
}

.playerHorizontal {
  transform: rotate(-90deg) rotateY(0deg) rotateX(0deg);
  transform-origin: center;
}

.horizontalTactic {
  transform: rotate(90deg) translateX(0px);
  transform-origin: center;
  transform-style: preserve-3d;
}
