.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (width >= 768px) {
    gap: 40px;
  }
}

.title {
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;
  text-transform: uppercase;

  @media (width >= 768px) {
    font-weight: 900;
    font-size: 48px;
    line-height: 44px;
    color: #a3a9b5;
  }
}

.desktopLineUp {
  display: none;

  @media (width >= 768px) {
    display: flex;
    justify-content: center;
    gap: 144px;
  }
}

.mobileLineUp {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (width >= 768px) {
    display: none;
  }
}

.lineupButton {
  position: relative;
  background-color: #070f15bf;
  border: 2px solid #272c32;
  padding: 17px;
  border-radius: 12px;
  cursor: pointer;

  @media (width >= 768px) {
    display: none;
  }
}

.teamLogo {
  position: absolute;
  top: 0;
  left: 12px;
  width: 56px;
  height: 56px;

  & > img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}

.modalTeamLogo {
  width: 64px;
  height: 64px;

  & > img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
}

.modalTeamName {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  color: #ffffff;
}

.modalTeamFrom {
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #d0d5dd;
}
