.dorsalContent {
  width: fit-content;
  display: flex;
  gap: 5px;
  padding: 5px 10px;
  background-color: #272c32;
  border-radius: 40px;
}

.dorsal {
  font-size: 14px;
  font-weight: 600;
  color: white;
}
