.optionSelector {
  z-index: 3;
  position: absolute;
  border-radius: 8px;
  background-color: #070f15;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (width < 768px) {
    display: none;
  }
}

.optionButton {
  all: unset;
  cursor: pointer;

  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  padding: 4px 8px;
  border-radius: 4px;
  width: 100%;

  @media (width >= 768px) {
    width: -webkit-fill-available;
  }

  &:hover {
    background-color: #383e47;
  }
}

.optionText {
  font-size: 16px;
  font-weight: 500;
  color: white;
  white-space: nowrap;
}

.overlay {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.image {
  width: 48px;
  height: 48px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-left: 12px !important;
}

.dropdownButton {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 48px;
  height: 48px;
}
