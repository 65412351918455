.container {
  display: flex;
  gap: 12px;
  background-color: #070f15b2;
  border: 2px solid #272c32;
  border-radius: 12px;
  padding: 12px;
  width: fit-content;
  margin-top: 20px;
}

.colorContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.color {
  width: 15px;
  height: 15px;
  border-radius: 4px;
}

.text {
  font-size: 14px;
  font-weight: 500;
  color: #d0d5dd;
}
