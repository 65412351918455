.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  padding: 36px 24px 24px;
  border-radius: 12px;

  @media (width >= 768px) {
    border-radius: 20px;
  }

  @media (width >= 1200px) {
    padding: 64px;
  }
}

.title {
  font-size: 25px !important;
  font-weight: 800 !important;
  line-height: 29px !important;
  margin: 0;

  @media (width >= 768px) {
    font-size: 56px !important;
    font-weight: 900 !important;
    line-height: 60px !important;
  }
}

.subtitle {
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  text-transform: none !important;

  @media (width >= 768px) {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
  }
}

.content {
  display: flex;
  flex-direction: column;

  @media (width >= 1200px) {
    flex-direction: row;
    gap: 80px;
  }
}

.leftSection {
  display: none;

  @media (width >= 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
}

.rightSection {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media (width >= 1200px) {
    width: 50%;
  }
}

.logo {
  width: -webkit-fill-available;

  @media (width >= 1200px) {
    max-width: 400px;
    max-height: 400px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (width >= 1200px) {
    width: 50%;
  }
}

.sendContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  @media (width >= 760px) {
    flex-direction: row;
    margin-top: 8px;

    & > input {
      padding: 12px 24px;
    }
  }
}

.sentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media (width >= 768px) {
    align-items: center;
  }
}

.sent1 {
  font-size: 15px;
  font-weight: 500;
  color: #d0d5dd;

  @media (width >= 768px) {
    font-size: 20px;
  }
}

.sent2 {
  font-size: 13px;
  color: #d0d5dd;
  font-weight: 500;

  @media (width >= 768px) {
    font-size: 16px;
    text-align: center;
  }
}

.privacyPolicy {
  display: flex;
  align-items: center;
  gap: 5px;

  & > a {
    color: white !important;
    font-weight: 700;
  }
}
