.teamLogo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.teamLogo2 {
  width: 112px;
  height: 112px;
  border-radius: 50%;
}

.dayTitle {
  font-size: 32px;
  font-weight: 800;
  color: #d0d5dd;
}

.rankingContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;

  @media (width >= 768px) {
    gap: 20px;
  }
}

.topRankingContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: #070f15b2;
  /* border-radius: 20px 20px 0 0; */
}

.topRankingHeaderContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (width >= 768px) {
    flex-direction: row;
  }
}

.topRankingHeaderContent {
  display: flex;
  align-items: center;
  gap: 12px;
}

.topPositionContainer {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  top: 60px;
  right: -16px;

  @media (width >= 768px) {
    display: none;
  }
}

.topPositionContent {
  padding: 10px 15px;
  width: fit-content;
  border: 2px solid #272c32;
  border-radius: 8px;
}

.topPositionMobile {
  font-size: 21px;
  font-weight: 900;
  color: #ecd183;
  font-family: Inter;
}

.topPosition {
  font-size: 60px;
  font-weight: 700;
  color: #ecd183;
  font-family: Inter;
}

.award {
  height: 88px;
  width: 83px;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 88px;
}

.playerContainer {
  display: flex;
  gap: 12px;
}

.playerAvatar > img {
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #272c32;

  @media (width >= 768px) {
    width: 112px;
    height: 112px;
    border-width: 4px;
  }
}

.playerData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.positionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.positionContainerMobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.dorsalContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.dorsalContainerMobile {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 46px;
}

.dorsal {
  font-size: 14px;
  font-weight: 600;

  @media (width >= 768px) {
    font-size: 60px;
    font-weight: 700;
  }
}

.dorsalContent {
  display: flex;
  gap: 5px;
  padding: 5px 10px;
  background-color: #272c32;
  border-radius: 40px;
}

.position {
  font-size: 14px;
  font-weight: 600;
  background-color: #272c32;
  border-radius: 50%;
  padding: 5px 10px;

  @media (width >= 768px) {
    border-radius: none;
    background-color: transparent;
    font-size: 24px;
    font-weight: 900;
    text-align: end;
  }
}

.labelValuesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 16px;
  width: 100%;
  gap: 4px;
}

.labelValueContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border: 2px solid #272c32;
  border-radius: 12px;
  padding: 17px 32px;
  background-color: #070f15bf;
}

.label {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.value {
  font-size: 24px;
  font-weight: 700;
}

.text {
  font-size: 14px;
  font-weight: 600;

  @media (width >= 768px) {
    font-size: 16px;
  }
}

.playerName {
  font-size: 18px;
  font-weight: 600;

  @media (width >= 768px) {
    font-size: 36px;
    font-weight: 700;
  }
}
