.display2xlRegular {
  /* font-family: Inter; */
  font-size: 4.5rem; /* 72px */
  font-weight: 400;
  line-height: 90px;
  letter-spacing: -0.02em;
}

.display2xlMedium {
  /* font-family: Inter; */
  font-size: 4.5rem; /* 72px */
  font-weight: 500;
  line-height: 90px;
  letter-spacing: -0.02em;
}

.display2xlSemibold {
  /* font-family: Inter; */
  font-size: 4.5rem; /* 72px */
  font-weight: 600;
  line-height: 90px;
  letter-spacing: -0.02em;
}

.display2xlBold {
  /* font-family: Inter; */
  font-size: 4.5rem; /* 72px */
  font-weight: 700;
  line-height: 90px;
  letter-spacing: -0.02em;
}

.displayxlRegular {
  /* font-family: Inter; */
  font-size: 3.75rem; /* 60px */
  font-weight: 400;
  line-height: 72px;
  letter-spacing: -0.02em;
}

.displayxlMedium {
  /* font-family: Inter; */
  font-size: 3.75rem; /* 60px */
  font-weight: 500;
  line-height: 72px;
  letter-spacing: -0.02em;
}

.displayxlSemibold {
  /* font-family: Inter; */
  font-size: 3.75rem; /* 60px */
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -0.02em;
}

.displayxlBold {
  /* font-family: Inter; */
  font-size: 3.75rem; /* 60px */
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.02em;
}

.displaylgRegular {
  /* font-family: Inter; */
  font-size: 3rem; /* 48px */
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -0.02em;
}

.displaylgMedium {
  /* font-family: Inter; */
  font-size: 3rem; /* 48px */
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -0.02em;
}

.displaylgSemibold {
  /* font-family: Inter; */
  font-size: 3rem; /* 48px */
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.02em;
}

.displaylgBold {
  /* font-family: Inter; */
  font-size: 3rem; /* 48px */
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.02em;
}

.displaymdRegular {
  /* font-family: Inter; */
  font-size: 2.25rem; /* 36px */
  font-weight: 400;
  line-height: 44px;
}

.displaymdMedium {
  /* font-family: Inter; */
  font-size: 2.25rem; /* 36px */
  font-weight: 500;
  line-height: 44px;
}

.displaymdSemibold {
  /* font-family: Inter; */
  font-size: 2.25rem; /* 36px */
  font-weight: 600;
  line-height: 44px;
}

.displaymdBold {
  /* font-family: Inter; */
  font-size: 2.25rem; /* 36px */
  font-weight: 700;
  line-height: 44px;
}

.displaysmRegular {
  /* font-family: Inter; */
  font-size: 1.875rem; /* 30px */
  font-weight: 400;
  line-height: 38px;
}

.displaysmMedium {
  /* font-family: Inter; */
  font-size: 1.875rem; /* 30px */
  font-weight: 500;
  line-height: 38px;
}

.displaysmSemibold {
  /* font-family: Inter; */
  font-size: 1.875rem; /* 30px */
  font-weight: 600;
  line-height: 38px;
}

.displaysmBold {
  /* font-family: Inter; */
  font-size: 1.875rem; /* 30px */
  font-weight: 700;
  line-height: 38px;
}

.displayxsRegular {
  /* font-family: Inter; */
  font-size: 1.5rem; /* 24px */
  font-weight: 400;
  line-height: 32px;
}

.displayxsMedium {
  /* font-family: Inter; */
  font-size: 1.5rem; /* 24px */
  font-weight: 500;
  line-height: 32px;
}

.displayxsSemibold {
  /* font-family: Inter; */
  font-size: 1.5rem; /* 24px */
  font-weight: 600;
  line-height: 32px;
}

.displayxsBold {
  /* font-family: Inter; */
  font-size: 1.5rem; /* 24px */
  font-weight: 700;
  line-height: 32px;
}

.textxlRegular {
  /* font-family: Inter; */
  font-size: 1.25rem; /* 20px */
  font-weight: 400;
  line-height: 30px;
}

.textxlMedium {
  /* font-family: Inter; */
  font-size: 1.25rem; /* 20px */
  font-weight: 500;
  line-height: 30px;
}

.textxlSemibold {
  /* font-family: Inter; */
  font-size: 1.25rem; /* 20px */
  font-weight: 600;
  line-height: 30px;
}

.textxlBold {
  /* font-family: Inter; */
  font-size: 1.25rem; /* 20px */
  font-weight: 700;
  line-height: 30px;
}

.textlgRegular {
  /* font-family: Inter; */
  font-size: 1.125rem; /* 18px */
  font-weight: 400;
  line-height: 28px;
}

.textlgMedium {
  /* font-family: Inter; */
  font-size: 1.125rem; /* 18px */
  font-weight: 500;
  line-height: 28px;
}

.textlgSemibold {
  /* font-family: Inter; */
  font-size: 1.125rem; /* 18px */
  font-weight: 600;
  line-height: 28px;
}

.textlgBold {
  /* font-family: Inter; */
  font-size: 1.125rem; /* 18px */
  font-weight: 700;
  line-height: 28px;
}

.textmdRegular {
  /* font-family: Inter; */
  font-size: 1rem; /* 16px */
  font-weight: 400;
  line-height: 24px;
}

.textmdMedium {
  /* font-family: Inter; */
  font-size: 1rem; /* 16px */
  font-weight: 500;
  line-height: 24px;
}

.textmdSemibold {
  /* font-family: Inter; */
  font-size: 1rem; /* 16px */
  font-weight: 600;
  line-height: 24px;
}

.textmdBold {
  /* font-family: Inter; */
  font-size: 1rem; /* 16px */
  font-weight: 700;
  line-height: 24px;
}

.textsmRegular {
  /* font-family: Inter; */
  font-size: 0.875rem; /* 14px */
  font-weight: 400;
  line-height: 20px;
}

.textsmMedium {
  /* font-family: Inter; */
  font-size: 0.875rem; /* 14px */
  font-weight: 500;
  line-height: 20px;
}

.textsmSemibold {
  /* font-family: Inter; */
  font-size: 0.875rem; /* 14px */
  font-weight: 600;
  line-height: 20px;
}

.textsmBold {
  /* font-family: Inter; */
  font-size: 0.875rem; /* 14px */
  font-weight: 700;
  line-height: 20px;
}

.textxsRegular {
  /* font-family: Inter; */
  font-size: 0.75rem; /* 12px */
  font-weight: 400;
  line-height: 16px;
}

.textxsMedium {
  /* font-family: Inter; */
  font-size: 0.75rem; /* 12px */
  font-weight: 500;
  line-height: 16px;
}

.textxsSemibold {
  /* font-family: Inter; */
  font-size: 0.75rem; /* 12px */
  font-weight: 600;
  line-height: 16px;
}

.textxsBold {
  /* font-family: Inter; */
  font-size: 0.75rem; /* 12px */
  font-weight: 700;
  line-height: 16px;
}

.titleTest {
  font-size: 17px;
  font-weight: 800;
  color: white;
  line-height: 21px;

  @media (width >= 768px) {
    font-size: 22px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0.02em;
  }
}

.subtitleTest {
  font-size: 13px;
  font-weight: 500;
  color: white;
  line-height: 17px;

  /* TODO Quitar estilos globales  */
  margin: 0;

  @media (width >= 768px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
  }
}

.linkTest {
  font-size: 16px;
  font-weight: 700;
  color: white;
  line-height: 20px;
}

.greyTitle3 {
  /* font-family: Inter; */
  font-size: 2rem; /* 32px */  
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.02em;
}

.title2 {
  /* font-family: Inter; */
  font-size: 1.0625rem; /* 17px */
  font-weight: 900;
  line-height: 21px;
  letter-spacing: -0.02em;

  @media (width >= 768px) {
    /* font-family: Inter; */
    font-size: 3rem; /* 48px */
    font-weight: 900;
    line-height: 44px;
    letter-spacing: -0.02em;
  }
}

.title3 {
  /* font-family: Inter; */
  font-size: 2.5rem; /* 40px */
  font-weight: 900;
  line-height: 44px;
  letter-spacing: -0.02em;
}

.mobileTitle3 {
  /* font-family: Inter; */
  font-size: 1.3125rem; /* 21px */
  font-weight: 900;
  line-height: 25px;
  letter-spacing: -0.02em;
}

.mobileTitle5 {
  /* font-family: Inter; */
  font-size: 0.9375rem; /* 15px */
  font-weight: 900;
  line-height: 19px;
  letter-spacing: -0.02em;
}
