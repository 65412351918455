.videoContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    border-radius: 12px;
  }

  @media (width >= 768px) {
    flex-direction: row-reverse;
    gap: 20px;

    & > div {
      border-radius: 20px;
    }
  }
}

.title {
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;
  text-transform: uppercase;

  @media (width >= 768px) {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
  }
}

.dataRowWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (width >= 768px) {
    flex-direction: row;
    gap: 48px;
  }
}

.separator {
  width: 1px;
  height: auto;
  border: 1px solid #272c32;
}

.dataRowContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  @media (width >= 768px) {
    gap: 16px;
  }
}

.dataRowContent {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.dataRowLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #a3a9b5;

  @media (width >= 768px) {
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
}

.dataRowValue {
  font-family: Inter;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #ffffff;
  text-align: end;

  @media (width >= 768px) {
    font-family: Inter;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
}

.containerComponent {
  gap: 20px;

  @media (width >= 768px) {
    max-width: 500px;
    gap: 24px;
  }
}

.containerComponent2 {
  gap: 20px;

  @media (width >= 768px) {
    gap: 24px;
  }
}
