.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media (width >= 768px) {
    width: 'auto';
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.teamLogo {
  width: 104px;
  height: 104px;
  border-radius: 50%;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
}

.teamInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.teamName {
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.teamFrom {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #d0d5dd;
}

.playersContainer {
  display: flex;
  flex-direction: column;

  @media (width >= 768px) {
    gap: 8px;
  }
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 12px;

  @media (width >= 768px) {
    margin-top: 16px;
  }
}

.playerContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  border-bottom: 1px solid #272c32;
  padding: 8px 0;

  @media (width >= 768px) {
    border: none;
    padding: 0;
    gap: 8px;
  }
}

.dorsalContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 46px;

  @media (width >= 768px) {
    gap: 8px;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.playerStats {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;

  @media (width >= 768px) {
    width: auto;
    gap: 8px;
  }

  & > a {
    width: 100%;
    color: #ffffff !important;

    @media (width >= 768px) {
      width: auto;
    }
  }
}
