.container {
  display: flex;
  flex-direction: row;
  position: relative;
  overflow-x: auto;
  width: 100%;

  @media (width >= 768px) {
    overflow-x: hidden;
  }
}

.content {
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (width >= 768px) {
    gap: 40px;
  }
}

.lastColumnStage {
  min-width: 280px;
}

.winnerContainer {
  position: absolute;
  top: 6%;
}

.winnerContainerXL {
  position: absolute;
  top: 22%;
}

.winnerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.thirdFourthMatchContainer {
  position: absolute;
  bottom: 21%;
}

.thirdFourthMatchContainerXL {
  position: absolute;
  bottom: 31%;
}

.knockoutTrophy {
  width: 140px;
  height: 140px;
  object-fit: contain;
}
