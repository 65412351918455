.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #272c32;
  border-radius: 12px;
  background-color: #070f15bf;
  padding: 16px;
  gap: 8px;
  width: 320px;
  height: 177px;

  @media (width >= 768px) {
    width: 360px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;

  & > p {
    font-size: 14px;
    font-weight: 500;
    color: #d0d5dd;
  }
}
