.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-weight: 900;
  font-size: 24px;
  color: #959aa2;
}

.content {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 20px;

  @media (width >= 768px) {
    gap: 0px;
  }
}
