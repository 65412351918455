.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 24px;
  border: 2px solid #272c32;
  border-radius: 20px;
  background-color: #070f15bf;
  padding: 24px;
  align-items: center;

  @media (width >= 1440px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 80px;
    padding: 80px 10px;
  }
}

.fieldContainer {
  max-width: 674px;
}

.playersContainer {
  width: 100%;
  display: flex;
  justify-content: center;

  @media (width >= 768px) {
    width: unset;
  }
}

.playersList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.positionsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.playerRow {
  display: flex;
  align-items: center;
  gap: 8px;

  & > img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: contain;
  }
}

.playerPosition {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  background-color: #272c32;

  & > p {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
}

.playerName {
  & > a {
    font-size: 14px;
    font-weight: 600;
    color: #fff !important;
  }
}

.teamLogo {
  & > img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: contain;
  }
}
