.teamDataContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.teamLink {
  font-size: 16px;
  font-weight: 600;

  @media (width >= 768px) {
    font-size: 20px;
    font-weight: 600;
  }
}

.teamLink2 {
  font-size: 36px;
}

.img {
  object-fit: cover;
  border-radius: 50%;
}
