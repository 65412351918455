:root {
  --good-color: #4caf50;
  --bad-color: #f44336;
}

.moodBarContainer {
  display: flex;
  /* justify-content: center; */
  gap: 4px;
  overflow: hidden;
}

.moodBarBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.moodBarBar {
  width: 100%;
  height: 12px;
  border-radius: 16px;
}

.text {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #a3a9b5;
}
