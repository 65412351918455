.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #272c32;
  border-radius: 12px;
  background-color: #070f15bf;
  padding: 16px;
  gap: 8px;
  width: 320px;
  height: 91px;

  @media (width >= 768px) {
    width: 360px;
  }
}

.winner {
  border-color: #e4c261;
  border-width: 4px;
}
