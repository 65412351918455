.container {
  background-color: #070f15bf;
  border-radius: 12px;
  height: 60px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 100%;
  /* padding: 4px; */

  @media (width > 768px) {
    width: fit-content;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (width > 768px) {
    width: fit-content;
  }
}

/* when is first element, border-radius top left and bottom left, when is last element, border-radius top right and bottom right */
.button:first-child {
  border-radius: 12px 0px 0px 12px;
}

.button:last-child {
  border-radius: 0px 12px 12px 0px;
}

.button {
  background-color: #070f15bf;
  padding: 8px 20px;
  font-size: 16px;
  border: 0px;
  height: 100%;
  font-weight: 500;
  width: 100%;

  @media (width > 768px) {
    cursor: pointer;
    width: fit-content;
  }
}

.buttonSelected {
  background-color: #383e47;
  color: #fff;
  font-size: 16px;
  width: 100%;
  border: 0px;
  border-radius: 8px;
  padding: 8px 20px;
  height: 100%;
  font-weight: 600;

  @media (width > 768px) {
    cursor: pointer;
    width: fit-content;
  }
}

.content {
  gap: 8px;
}
