.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (width >= 768px) {
    gap: 24px;
  }
}

.title {
  font-weight: 900;
  font-size: 23px;
  line-height: 27px;
  color: #a3a9b5;

  @media (width >= 768px) {
    font-size: 32px;
    line-height: 36px;
  }
}
