.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  @media (width >= 768px) {
    flex-direction: row;
  }
}

.titlesContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  @media (width >= 768px) {
    align-items: flex-start;
  }
  span {
    text-transform: uppercase;
  }
}
