.mobile {
  display: inherit;

  @media (width >= 767px) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (width >= 767px) {
    display: inherit;
  }
}

.fullWidth {
  width: 100%;
}

.removeButtonStyles {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  appearance: button;
  cursor: pointer;
}

.generalBorderTopBlock {
  border-top: 2px solid #272c32;
}

.border {
  border: 2px solid #272c32;
}

.radius20 {
  border-radius: 20px;
}
