.selectorsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (width >= 768px) {
    gap: 40px;
  }
}

.stageSelectorContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}

.stageSelectorContent {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  @media (width >= 768px) {
    width: fit-content;
  }
}

.stageTitle {
  font-size: 48px;
  font-weight: 900;
  color: #d0d5dd;
}

.stageLabel {
  display: none;
  @media (width >= 768px) {
    display: block;
  }
}

.dropdown {
  width: 100%;

  @media (width >= 768px) {
    width: fit-content;
  }
}

.dayTitle {
  font-size: 32px;
  font-weight: 800;
  color: #d0d5dd;
}
