.container {
  overflow: auto;
}

.swiperSlide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 20px;
  min-height: 60px;

  @media (width > 768px) {
    min-height: 220px;
  }
}

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #070f15d9;
  height: 10px;
  border-radius: 12px;
  border: 2px solid #272c32;
  padding: 8px;
  top: 12px !important;
  right: 12px;
  left: unset !important;
  width: fit-content !important;
  gap: 4px;

  @media (width > 768px) {
    height: 10px;
    top: 24px !important;
    right: 24px;
    padding: 8px;
    border-radius: 12px;
  }
}

.paginationItem {
  background-color: #959aa2;
  width: 8px;
  height: 8px;
  border-radius: 12px;

  @media (width > 768px) {
    width: 10px;
    height: 10px;
  }

  &:hover {
    cursor: pointer;
  }
}

.paginationItemSelected {
  background-color: #fff;
  width: 40px;
  height: 8px;
  border-radius: 12px;

  @media (width > 768px) {
    height: 10px;
  }
}
