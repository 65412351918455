.knockoutMatchLineCenter {
  height: 25px;
  position: absolute;
  z-index: -1;
  top: 55%;
  right: 0px;
  border-width: 4px 0px 0px 0px;
  border-color: #6d737f;
  border-style: solid;
  width: 23px;
  left: -24px;

  @media (width >= 768px) {
    width: 43px;
    left: -44px;
  }
}

.knockoutMatchLineCenter2 {
  height: 25px;
  position: absolute;
  z-index: -1;
  top: 55%;
  border-width: 4px 0px 0px 0px;
  border-color: #6d737f;
  border-style: solid;

  width: 23px;
  right: -24px;

  @media (width >= 768px) {
    width: 43px;
    right: -44px;
  }
}

.knockoutMatchLineBottom {
  width: 85px;
  height: 25px;
  position: absolute;
  bottom: -26px;
  left: -22px;
  right: 0px;
  border-width: 0px 4px 4px 0px;
  border-color: #6d737f;
  border-style: solid;
  z-index: -1;
  border-bottom-right-radius: 20px;

  @media (width >= 768px) {
    left: -42px;
  }
}

.knockoutMatchLineBottomXL {
  width: 85px;
  height: 113px;
  position: absolute;
  bottom: -114px;
  left: -22px;
  right: 0px;
  border-width: 0px 4px 4px 0px;
  border-color: #6d737f;
  border-style: solid;
  z-index: -1;
  border-bottom-right-radius: 20px;

  @media (width >= 768px) {
    left: -42px;
  }
}

.knockoutMatchLineTopWinner {
  width: 150px;
  height: 69px;
  margin: auto;
  position: absolute;
  top: -40%;
  border-width: 0px 4px 0px 0px;
  border-color: #6d737f;
  border-style: solid;
  z-index: -1;

  @media (width >= 768px) {
    width: 172px;
    height: 63px;
    top: -37%;
  }
}

.knockoutMatchLineTopWinnerXL {
  width: 150px;
  height: 75px;
  margin: auto;
  position: absolute;
  top: -43%;
  border-width: 0px 4px 0px 0px;
  border-color: #6d737f;
  border-style: solid;
  z-index: -1;

  @media (width >= 768px) {
    width: 172px;
    height: 82px;
    top: -47%;
  }
}

.knockoutMatchLineTopWinnerExtraHeight {
  width: 150px;
  height: 59px;
  margin: auto;
  position: absolute;
  top: -34%;
  border-width: 0px 4px 0px 0px;
  border-color: #6d737f;
  border-style: solid;
  z-index: -1;

  @media (width >= 768px) {
    width: 172px;
    height: 63px;
    top: -37%;
  }
}

.knockoutMatchLineTopWinnerExtraHeightXL {
  width: 150px;
  height: 75px;
  margin: auto;
  position: absolute;
  top: -43%;
  border-width: 0px 4px 0px 0px;
  border-color: #6d737f;
  border-style: solid;
  z-index: -1;

  @media (width >= 768px) {
    width: 172px;
    height: 82px;
    top: -47%;
  }
}

.knockoutMatchLineExtraHeightBottom {
  height: 153px !important;
  bottom: unset !important;
  top: 175px !important;
}

.knockoutMatchLineExtraHeightXLBottom {
  height: 297px !important;
  bottom: unset !important;
  top: 175px !important;
}

.bottomRight {
  border-width: 0px 0px 4px 4px;
  right: -22px !important ;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0 !important;

  @media (width >= 768px) {
    right: -42px !important;
  }
}

.topRight {
  border-width: 4px 0px 0px 4px !important;
  right: -22px !important ;
  border-top-left-radius: 20px;
  border-top-right-radius: 0 !important;

  @media (width >= 768px) {
    right: -42px !important;
  }
}

.knockoutMatchLineTop {
  width: 85px;
  height: 25px;
  position: absolute;
  top: -26px;
  left: -22px;
  right: 0px;
  border-width: 4px 4px 0px 0px;
  border-color: #6d737f;
  border-style: solid;
  z-index: -1;
  border-top-right-radius: 20px;

  @media (width >= 768px) {
    left: -42px;
  }
}

.knockoutMatchLineTopExtraHeight {
  width: 85px;
  height: 99px;
  position: absolute;
  top: -99px;
  left: -22px;
  right: 0px;
  border-width: 4px 4px 0px 0px;
  border-color: #6d737f;
  border-style: solid;
  z-index: -1;
  border-top-right-radius: 20px;

  @media (width >= 768px) {
    left: -42px;
  }
}

.knockoutMatchLineExtraHeightTop {
  height: 143px;
  bottom: 175px;
  top: unset;
}

.knockoutMatchLineExtraHeightXLTop {
  height: 289px;
  bottom: 175px;
  top: unset;
}

.winnerLine {
  border-color: #e4c261;
}

.knockoutMatchFinalContainer {
  left: 44%;
  position: absolute;
}
