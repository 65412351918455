.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftSection {
  display: flex;
  align-items: center;
  gap: 12px;

  & > p > a {
    font-size: 15px;
    font-weight: 600;
  }
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 6px;
}

.logo {
  & > a > img {
    height: 48px;
    width: 48px;
    object-fit: cover;
  }
}

.teamName {
  margin: 0 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.penaltiesWinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ff4d00;

  & > p {
    font-weight: 600;
    color: white;
  }
}

.scoreContainer {
  width: 44px;
  height: 44px;
  border: 2px solid #272c32;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scoreNum {
  font-size: 17px;
  font-weight: 600;
  color: white;
}

.matchTeamDescription {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.isWinner {
  color: #6ce9a6;

  & > a {
    color: #6ce9a6 !important;
  }
}

.isNotWinner {
  color: white;

  & > a {
    color: white !important;
  }
}

.winnerIndicator {
  position: absolute;
  left: 0;
  width: 4px;
  height: 68px;
  padding: 12px 0;
}

.winnerIndicatorIcon {
  display: block;
  width: 4px;
  height: 48px;
  background-color: #32d583;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
