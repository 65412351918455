.arrowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 64px;
}

.statusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.teamDataContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.teamLogo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.teamName {
  font-size: 14px;
  width: max-content;

  @media (width > 768px) {
    font-size: 16px;
  }
}
