.ArticleSummary {
  flex: 1 1 100%;
  /* margin: 0 0px 15px 0; */
  height: 280px;
  position: relative;
  background-color: #3e3c52;
  background-repeat: none;
  background-size: cover;
  background-position: center top;
  border-radius: 20px;
}

.WithVideo {
  height: auto;
}

.Summary {
  background: #070f15d9;
  padding: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.video {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
