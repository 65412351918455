.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (width >= 768px) {
    gap: 26px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @media (width >= 768px) {
    gap: 20px;
  }

  & > svg {
    width: 24px;
    height: 24px;

    @media (width >= 768px) {
      width: 40px;
      height: 40px;
    }
  }
}

.title {
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;

  @media (width >= 768px) {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;

  @media (width >= 768px) {
    gap: 16px;
  }
}

.mvp {
  display: flex;
  align-items: center;
  gap: 8px;

  @media (width >= 768px) {
    gap: 20px;
  }
}

.mvpData {
  @media (width >= 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
}

.imageWrapper {
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 12px 0px #6ef8bd;

  @media (width >= 768px) {
    width: 88px;
    height: 88px;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 50%;
    background: linear-gradient(90deg, #6df775 -3.29%, #77ffb2 104.19%);
    -webkit-mask:
      linear-gradient(white 0 0) content-box,
      linear-gradient(white 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  & > img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;

    @media (width >= 768px) {
      width: 88px;
      height: 88px;
    }
  }
}

.name {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: #ffffff;

  @media (width >= 768px) {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
  }
}

.playerName {
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  background: linear-gradient(90deg, #6df775 -3.29%, #77ffb2 104.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (width >= 768px) {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
  }
}

.apparelNumber {
  font-weight: 900;
  font-size: 40px;
  line-height: 44px;
  color: #6df775;
}

.playerPosition {
  font-weight: 900;
  font-size: 21px;
  line-height: 25px;
  color: #e4e7ec;
}

.teamName {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  color: #ffffff;

  @media (width >= 768px) {
    font-weight: 900;
    font-size: 21px;
    line-height: 25px;
  }
}

.mvpDetailsContainer {
  display: flex;
  gap: 4px;
}

.mvpDetailsValueContainer {
  display: flex;
  align-items: baseline;
}

.mvpDetailsValueQuantity {
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -2%;
  color: #ffffff;

  @media (width >= 768px) {
    font-weight: 900;
    font-size: 17px;
    line-height: 21px;
  }
}

.mvpDetailsValue {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #a3a9b5;
}

.mvpDetailsLabel {
  font-weight: 900;
  font-size: 32px;
  line-height: 36px;
  color: #ffffff;

  @media (width >= 768px) {
    font-family: Inter;
    font-weight: 900;
    font-size: 32px;
    line-height: 36px;
  }
}
